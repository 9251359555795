import React, { useState, useEffect, useRef } from "react";

import SegmentedControl from "./SegmentedControl";

import "../css/styles.css";
import "mapbox-gl/dist/mapbox-gl.css";

import mapboxgl from "mapbox-gl";
// eslint-disable-line import/no-webpack-loader-syntax

import json from "./geojson.json";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGVyYXNtdXMiLCJhIjoiY2w0YmdqczE0MWx0aDNlbXNzZ2pkMDY2bCJ9.ddZczyl09qOPtDtBm6HF3A";

export default function DelphosMap(props: MapProps) {
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const [lng, setLng] = useState(-79.02082147536673);
  const [lat, setLat] = useState(14.17048546826947);
  const [zoom, setZoom] = useState(3.55);
  const previousSelectedCountry = useRef<any>(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: "map", //mapContainer.current,
      style: "mapbox://styles/derasmus/cl2ksfxge001a15p66h0gktfk",
      center: [lng, lat], // starting position [lng, lat]
      zoom: zoom, // starting zoom
      scrollZoom: false,
      dragRotate: false,
      dragPan: false,
      boxZoom: false,
      doubleClickZoom: false,
      touchZoomRotate: false,
      touchPitch: false,
      keyboard: false,
    });

    map.current.resize();
  });

  useEffect(() => {
    for (var feature of json.features) {
      const el = document.createElement("div");
      el.className = "map-marker";
      el.id = feature.properties.ADMIN;
      el.style.backgroundImage = `url('\map-pin-white.png')`;

      el.addEventListener("click", (event: any) => {
        if (event.target.id !== null) {
          const country = event.target.id;
          console.log(event.target);

          console.log(previousSelectedCountry);
          if (previousSelectedCountry.current !== null) {
            previousSelectedCountry.current.style.backgroundImage = `url('\map-pin-white.png')`;
          }
          previousSelectedCountry.current = event.target;

          event.target.style.backgroundImage = `url('\map-pin-blue.png')`;

          props.setSelectedCountry(country);
        }
      });
      const marker = new mapboxgl.Marker({ anchor: "bottom", element: el })
        .setLngLat([
          feature.geometry.coordinates[0],
          feature.geometry.coordinates[1],
        ])
        .addTo(map.current); // add the marker to the ma
    }
  }, []);

  function handleSegmentChange(value: string) {
    if (value === "s-america") {
      map.current.setZoom(2.61);
      map.current.panTo([-61.36236073880484, -14.359726830621057]);
    } else if (value === "africa") {
      map.current.setZoom(2.09);
      map.current.panTo([12.447364665398254, -9.7911083938823167]);
    } else if (value === "lat-america") {
      map.current.setZoom(3.55);
      map.current.panTo([-79.02082147536673, 14.17048546826947]);
    } else if (value === "asia") {
      map.current.setZoom(2.35);
      map.current.panTo([86.84929924787565, 20.20718635758648]);
    }
  }
  return (
    <div>
      <div className="sidebar">
        <SegmentedControl
          name="continent-selector"
          callback={handleSegmentChange}
          defaultIndex={0}
          controlRef={useRef()}
          segments={[
            {
              label: "Lat. America",
              value: "lat-america",
              ref: useRef(),
            },
            {
              label: "S. America",
              value: "s-america",
              ref: useRef(),
            },
            {
              label: "Africa",
              value: "africa",
              ref: useRef(),
            },
            {
              label: "Asia",
              value: "asia",
              ref: useRef(),
            },
          ]}
        />
      </div>
      <div id="map" ref={mapContainer} className="map-container" />
    </div>
  );
}

interface MapProps {
  setSelectedCountry: any;
  affectedCountries: string[];
}

interface GeoJson {
  type: string;
  features: any[];
}
