import React from "react";

export default function Banner(props: { title: string }) {
  return (
    <div className="banner">
      <div className="overlay"></div>
      <div className="head-content">
        <h1>{props.title}</h1>
      </div>
    </div>
  );
}
