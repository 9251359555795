import React, { useState, useEffect, useContext, useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import Feed from "../Feed/Feed";
import Newsroom from "../Newsroom/Newsroom";
import Nav from "./Nav";
import Footer from "./Footer";
import NewsroomAritcle from "../Newsroom/NewsroomAritcle";
import HeatMap from "../HeatMap/HeatMap";

import { WindowSizeContext } from "./WindowSizeContext";

export default function Delphos() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={windowSize}>
      <div className="grid-container">
        <Nav />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/map" element={<HeatMap />} /> */}
            <Route path="/feed" element={<Feed />} />

            <Route path="/newsroom" element={<Newsroom />}></Route>
            <Route
              path="/newsroom/:newsroomArticleId"
              element={<NewsroomAritcle />}
            />
          </Routes>
        </div>

        {window.location.pathname + window.location.search !== "/" && (
          <Footer />
        )}
      </div>
    </WindowSizeContext.Provider>
  );
}
