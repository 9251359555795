import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBg-q-r8Na_ek-Ei93L8c2QXcd6wkHAgKM",
  authDomain: "webnews-270f7.firebaseapp.com",
  databaseURL: "https://webnews-270f7.firebaseio.com",
  projectId: "webnews-270f7",
  storageBucket: "webnews-270f7.appspot.com",
  messagingSenderId: "899873732269",
  appId: "1:899873732269:web:3ba1c871b7a31a6d63b824",
  measurementId: "G-BQBLLRFTN2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
