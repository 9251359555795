import React, { useEffect, useContext } from "react";
import Banner from "../Banner";

import { WindowSizeContext } from "../Delphos/WindowSizeContext";

export default function Home() {
  const { width, height } = useContext(WindowSizeContext);
  useEffect(() => {
    // <script
    //   src="https://widget.tagembed.com/embed.min.js"
    //   type="text/javascript"
    // ></script>;

    const script = document.createElement("script");

    script.src = "https://widget.tagembed.com/embed.min.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div className="feed-content">
      {/* <Banner title="Feed"  /> */}
      {/* <div className="banner" style={{ height: 150, padding: 0 }}>
        <div className="overlay"></div>
        <div className="head-content">
          <h1>Feed</h1>
        </div>
      </div> */}

      <div
        className="tagembed-container"
        style={{ width: "100%", height: height - 65, overflow: "auto" }}
      >
        <div
          className="tagembed-socialwall"
          data-wall-id="22654"
          view-url="https://widget.tagembed.com/22654?view"
        ></div>{" "}
        <script
          src="//widget.tagembed.com/embed.min.js"
          type="text/javascript"
        ></script>
      </div>

      {/* <iframe
        src="https://widget.tagembed.com/22654?view"
        style={{
          width: "100%",
          height: height - 65 - 150,
          overflow: "auto",
        }}
        frameBorder="0"
        allowTransparency={true}
      ></iframe> */}

      {/* <div className="linkedin-feed"></div> */}

      {/* <iframe
        className="feed"
        src="https://widget.tagembed.com/22654?view"
      ></iframe> */}
      {/* <div
        className="tagembed-container"
        style={{ width: "100%", height: "100%", overflow: "auto" }}
        // style=" width:100%;height:100%;overflow: auto;"
      >
        <div
          className="tagembed-socialwall"
          data-wall-id="22654"
          view-url="https://widget.tagembed.com/22654?view"
        >
          {" "}
        </div>{" "}
        <script
          src="//widget.tagembed.com/embed.min.js"
          type="text/javascript"
        ></script>
      </div> */}
    </div>
  );
}
