import React, { useState, useContext, useEffect } from "react";

import { WindowSizeContext } from "./WindowSizeContext";

export default function Nav() {
  const { width, height } = useContext(WindowSizeContext);
  const [showNavItems, setShowNavItems] = useState(width > 600 ? true : false);
  function handleNavClick() {
    setShowNavItems((prevValue) => !prevValue);
  }

  function checkWindowSize() {
    if (width > 600) {
      setShowNavItems(true);
    } else {
      setShowNavItems(false);
      if (width < 600 && width > 500) {
        // window.location.reload();
      }
    }
  }

  useEffect(() => {
    checkWindowSize();
  }, [width]);

  if (width > 600) {
    return (
      <nav className="nav" id="nav">
        <a href="/">
          <img src="/logos/word-blue.png" alt="" />
        </a>

        {showNavItems && (
          <div className="collapsable-items">
            <a href="/">Home</a>
            {/* <a href="/map">Map</a> */}
            <a href="/feed">Feed</a>
            <a href="/newsroom">Newsroom</a>
          </div>
        )}
        {window.innerWidth < 600 && (
          <button className="nav-toggle" onClick={handleNavClick}>
            <img src="/icons/bars.svg" alt="Nav button" />
            <i className="fi-xwsux2-three-bars-solid"></i>
          </button>
        )}
      </nav>
    );
  } else {
    return (
      // <div className="tab-bar">
      //   <a href="/">
      //     <div className="tab-bar-button">
      //       <img
      //         className="tab-bar-icon"
      //         src="/icons/home-icon.png"
      //         alt="Home icon"
      //       />
      //       <h3 className="tab-bar-label">Home</h3>
      //     </div>
      //   </a>
      //   <a href="/feed">
      //     <div className="tab-bar-button">
      //       <img
      //         className="tab-bar-icon"
      //         src="/icons/social-media-icon.png"
      //         alt="Feed icon"
      //       />
      //       <h3 className="tab-bar-label">Feed</h3>
      //     </div>
      //   </a>
      //   <a href="/newsroom">
      //     <div className="tab-bar-button">
      //       <img
      //         className="tab-bar-icon"
      //         src="/icons/news-icon.png"
      //         alt="Newsroom icon"
      //       />
      //       <h3 className="tab-bar-label">Newsroom</h3>
      //     </div>
      //   </a>
      // </div>
      <div></div>
    );
  }
}
