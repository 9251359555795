import React, { useContext, useEffect } from "react";

import { UserContext } from "../UserContext";

// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "../../firebase.js";

const NoAccount: React.FC = () => {
  const user = useContext(UserContext);

  useEffect(() => {
    if (user) {
      window.location.href = "/";
    }
  }, [user]);
  return (
    <div className="login-container">
      <div className="welcome-container">
        <img
          className="welcome-logo"
          src="/logos/complete-brand-white.png"
          alt="Delphos Logo"
        />
        <div className="welcome-buttons-container">
          <button
            onClick={() => {
              window.location.href = "/signup";
            }}
            className="welcome-button primary-color"
          >
            Sign Up
          </button>
          <button
            onClick={() => {
              window.location.href = "/login";
            }}
            className="welcome-button"
          >
            Login
          </button>
        </div>
      </div>
      <div className="logoArea">
        <img src="/logos/word-white.png" alt="Delphos Logo" />
      </div>
    </div>
  );
};

export default NoAccount;
