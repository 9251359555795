import React, { useState, useEffect, useContext } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase.js";

import { UserContext } from "../UserContext";

const SignUp: React.FC = () => {
  // const auth = getAuth();

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const user = useContext(UserContext);

  useEffect(() => {
    if (user) {
      window.location.href = "/";
    }
  }, [user]);

  function signUpNewUser() {
    if (email !== "" && password === password2 && password !== null) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert("Error: " + errorMessage);
          console.log("errorcode " + errorCode);
          // ..
        });
    }
  }

  function handleChange(event: any) {
    const { name, value } = event.target;

    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "password2") {
      setPassword2(value);
    }

    // setUser((prevValue) => {
    //   return { ...prevValue, [name]: value };
    // });
  }

  function handleSubmit(event: any) {
    signUpNewUser();
    setEmail("");
    setPassword("");
    setPassword2("");
    // event.preventDefault();
    // window.location.href = "/";
  }

  return (
    <div className="login-container">
      <div className="loginArea">
        <div className="loginForm">
          <div className="form">
            <h1>Sign Up</h1>
            <input
              onChange={handleChange}
              className="inputField"
              type="email"
              name="email"
              placeholder="Email"
              value={email}
            />
            <input
              onChange={handleChange}
              className="inputField"
              type="password"
              name="password"
              placeholder="Password"
              value={password}
            />
            <input
              onChange={handleChange}
              className="inputField"
              type="password"
              name="password2"
              placeholder="Reenter Password"
              value={password2}
            />
            <button onClick={handleSubmit}>Sign up</button>
          </div>
        </div>
      </div>
      <div className="logoArea">
        <img src="/logos/complete-brand-white.png" alt="Delphos Logo" />
      </div>
    </div>
  );
};

export default SignUp;
