import React, { useEffect, useContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./css/styles.css";
import Delphos from "./Delphos/Delphos";
import Login from "./Login/Login";
import NoAccount from "./Login/NoAccount";
import SignUp from "./Login/SignUp";
// import { addListener } from "../firebase";
import DelphosMap from "./HeatMap/DelphosMap";
import "mapbox-gl/dist/mapbox-gl.css";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import { auth } from "../firebase.js";

import { UserContext } from "./UserContext";

function App() {
  const [user, setUser] = useState<any>(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setUser(user);

        // ...
      } else {
        // User is signed out
        // ...
        setUser(null);

        const path = window.location.pathname.split("/");
        if (
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/welcome" &&
          window.location.pathname !== "/signup" &&
          path.length < 2
        ) {
          window.location.href = "/welcome";
        }
      }
    });
  }, []);
  return (
    <UserContext.Provider value={user}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Delphos />} />
          <Route path="/login" element={<Login />} />
          <Route path="/welcome" element={<NoAccount />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
