import React, { useState, useEffect } from "react";

export default function BottomCard(props: BottomCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const [description, setDescription] = useState<string>("");

  const [csvData, setCsvData] = useState<any[]>([]);

  useEffect(() => {
    getJson();
  }, []);

  useEffect(() => {
    const found = csvData.find((item: any) => {
      return item.country.toLowerCase() === props.selectedCountry.toLowerCase();
    });
    console.log(found);

    if (found !== undefined) {
      setDescription(found.description);
      console.log(found.description);
    }
  }, [props.selectedCountry]);

  const getJson = async () => {
    try {
      const res = await fetch("/data/delphos-heat-map-data-2.csv", {
        method: "get",
        headers: {
          "content-type": "text/csv;charset=UTF-8",
          //in case you need authorisation
          //'Authorization': 'Bearer ' + [TOKEN] //or what you like
        },
      });
      if (res.status === 200) {
        const csv = await res.text();

        const csvArray = await csvToArray(csv);
        setCsvData(csvArray);
      } else {
        console.log(`Error code ${res.status}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function csvToArray(str: string, delimiter = ","): any[] {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function(row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function(
        object: any,
        header: string,
        index: number
      ) {
        object[header] = values[index];
        return object;
      },
      {});

      return el;
    });

    // return the array
    return arr;
  }

  const cardStyles = { height: isExpanded ? "60%" : "20%" };

  function handleClick() {
    if (isExpanded) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }

  return (
    <div className="bottomCard" style={cardStyles}>
      <div className="space-between">
        <h1>
          {props.selectedCountry !== ""
            ? props.selectedCountry
            : "Discover Delphos"}
        </h1>
        <button onClick={handleClick}>
          {isExpanded ? "Show less" : "Show more"}
        </button>
      </div>

      <h3>
        {props.selectedCountry !== ""
          ? "Our activity in " + props.selectedCountry
          : "Click on a country to find out more"}
      </h3>

      {description !== "" && <h4>{description}</h4>}
    </div>
  );
}

interface BottomCardProps {
  selectedCountry: string;
}
