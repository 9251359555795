import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

// import { initializeApp } from "firebase/app";
import { db } from "../../firebase.js";

// //Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBg-q-r8Na_ek-Ei93L8c2QXcd6wkHAgKM",
//   authDomain: "webnews-270f7.firebaseapp.com",
//   databaseURL: "https://webnews-270f7.firebaseio.com",
//   projectId: "webnews-270f7",
//   storageBucket: "webnews-270f7.appspot.com",
//   messagingSenderId: "899873732269",
//   appId: "1:899873732269:web:3ba1c871b7a31a6d63b824",
//   measurementId: "G-BQBLLRFTN2",
// };
// const app = initializeApp(firebaseConfig);

// async function getData(params: any) {}

export default function NewsroomAritcle() {
  let params = useParams();

  const getData = async () => {
    const docRef = doc(db, "newsroomData", `${params.newsroomArticleId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      // window.location.href = "/newsroom";
    }
    return {};
  };
  const [docData, setDocData] = useState<any>({});

  useEffect(() => {
    const data = getData()
      .then((data) => {
        setDocData(data);
      })
      .catch((err) => {
        console.log("error getting document " + err);
      });
  }, []);

  return (
    <div className="newsroom-article-content">
      <Link to="/newsroom">
        <h4 className="back-to-newsroom">
          <img src="/icons/chevron-left.svg" alt="Chevron" />
          Back to Newsroom
        </h4>
      </Link>
      {Object.keys(docData).length === 0 && (
        <div className="center">
          <h2>Loading</h2>
        </div>
      )}
      <h3>{docData.source}</h3>
      <h1>{docData.title}</h1>
      <h2>{docData.dateString}</h2>
      <p>{docData.text}</p>

      {Object.keys(docData).length !== 0 && (
        <div className="align-items-right">
          <a href={docData.link}>
            <h3>Link to original article</h3>
          </a>
        </div>
      )}
    </div>
  );
}
