import React, { useState } from "react";
import "../css/newsroom.css";
import Banner from "../Banner";
import Hit from "./Hit";
import algoliasearch from "algoliasearch/lite";
import Footer from "../Delphos/Footer";
import {
  InstantSearch,
  SearchBox,
  Hits,
  ClearRefinements,
  RefinementList,
  Configure,
  SortBy,
  Pagination,
} from "react-instantsearch-dom";

const searchClient = algoliasearch(
  "HH1IL7UDHY",
  "ce1285c120ee8ef234b1324797271029"
);

export default function Newsroom() {
  const [isExpanded, setIsExpanded] = useState(
    window.innerWidth > 880 ? true : false
  );

  function expandedClick() {
    setIsExpanded((prevValue) => !prevValue);
  }
  return (
    <div>
      <Banner title="Newsroom" />
      <div className="newsroom-content">
        <InstantSearch searchClient={searchClient} indexName="newsroomData">
          <div className="left-panel">
            <div className="two-columns-grid">
              <h2>Filters</h2>
              <div className="align-items-right">
                <button className="expand-button" onClick={expandedClick}>
                  {isExpanded ? (
                    <img
                      className="arrow"
                      src="/icons/arrow-up.png"
                      alt="arrow"
                    />
                  ) : (
                    <img
                      className="arrow"
                      src="/icons/arrow-down.png"
                      alt="arrow"
                    />
                  )}
                </button>
              </div>
            </div>

            {isExpanded && (
              <div className="filter-items">
                <div className="two-columns-grid">
                  <h3>Order by</h3>
                  <div className="align-items-right">
                    <SortBy
                      defaultRefinement="newsroomData"
                      items={[
                        { value: "newsroomData", label: "Newest First" },
                        {
                          value: "newsroomData_Date_asc",
                          label: "Oldest First",
                        },
                      ]}
                    />
                  </div>
                </div>

                <h3>DFIs</h3>
                <RefinementList
                  attribute={"source"}
                  showMore={true}
                  searchable={true}
                  limit={14}
                  showMoreLimit={100}
                  facetOrdering={true}
                  translations={{
                    showMore(expanded: boolean) {
                      return expanded ? "Show less" : "Show more";
                    },
                    noResults: "No results",
                    placeholder: "Search DFIs",
                  }}
                />
                <div className="align-items-right">
                  <ClearRefinements />{" "}
                </div>

                <Configure hitsPerPage={20} />
              </div>
            )}
          </div>
          <div className="right-panel">
            <SearchBox
              translations={{
                placeholder: "Search newsroom",
              }}
            />

            <Hits hitComponent={Hit} />
            <Pagination totalPages={3} />
          </div>
        </InstantSearch>
      </div>
      <Footer />
    </div>
  );
}
