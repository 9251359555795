import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";
import DelphosMap from "./DelphosMap";
import BottomCard from "./BottomCard";
import SegmentedControl from "./SegmentedControl";

export default function HeatMap() {
  const [selectedCountry, setSelectedCountry] = useState<string>("");

  const [csvData, setCsvData] = useState<any[]>([]);
  const [affectedCountries, setAffectedCountries] = useState<any[]>([]);

  return (
    <div className="heatmap-container">
      <DelphosMap
        setSelectedCountry={setSelectedCountry}
        affectedCountries={affectedCountries}
      />
      <BottomCard selectedCountry={selectedCountry} />
    </div>
  );
}
