import React from "react";
import { Link } from "react-router-dom";

function Hit(props: any) {
  return (
    <Link to={`/newsroom/${props.hit.objectID}`}>
      <div className="result">
        <h5>{props.hit.source}</h5>
        <h2>{props.hit.title}</h2>
        <div>
          <h4>{props.hit.dateString}</h4>
        </div>

        <hr />
      </div>
    </Link>
  );
}

export default Hit;
