import React, { useContext, useEffect } from "react";
import { WindowSizeContext } from "./WindowSizeContext";

export default function Footer() {
  const year = new Date().getFullYear();
  const { width, height } = useContext(WindowSizeContext);

  if (width < 600) {
    return <div></div>;
  } else {
    return (
      <footer>
        <img src="/logos/complete-brand-white.png" alt="Delphos Logo" />
        <p>© Copyright {year} - Delphos International</p>
      </footer>
    );
  }
}
