import React, { useState, useEffect, useContext } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase.js";

import { UserContext } from "../UserContext";

const Login: React.FC = () => {
  const [loginUser, setLoginUser] = useState({ email: "", password: "" });
  const currentUser = auth.currentUser;

  const user = useContext(UserContext);

  useEffect(() => {
    if (user) {
      window.location.href = "/";
    }
  }, [user]);

  function signInNewUser(email: string, password: string) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        console.log("Signed In");
        const user = userCredential.user;
        window.location.href = "/";
        console.log("user " + JSON.stringify(userCredential));
        // window.location.reload();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Sign In Failed");
        console.log(errorMessage);
      });
  }

  function handleChange(event: any) {
    const { name, value } = event.target;
    setLoginUser((prevValue) => {
      return { ...prevValue, [name]: value };
    });
  }

  function handleSubmit(event: any) {
    signInNewUser(loginUser.email, loginUser.password);
    setLoginUser({ email: "", password: "" });
    event.preventDefault();
  }

  return (
    <div className="login-container">
      <div className="loginArea">
        <div className="loginForm">
          <div className="form">
            <h1>Login</h1>
            <input
              onChange={handleChange}
              className="inputField"
              type="email"
              name="email"
              placeholder="Email"
              value={loginUser.email}
            />
            <input
              onChange={handleChange}
              className="inputField"
              type="password"
              name="password"
              placeholder="Password"
              value={loginUser.password}
            />
            <button onClick={handleSubmit}>Let's Go</button>
          </div>
        </div>
      </div>
      <div className="logoArea">
        <img src="/logos/complete-brand-white.png" alt="Delphos Logo" />
      </div>
    </div>
  );
};

export default Login;
