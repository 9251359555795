import React from "react";
import Banner from "../Banner";
import "../css/home.css";
import Dial from "./Dial";
import HeatMap from "../HeatMap/HeatMap";
import { auth } from "../../firebase.js";
import { signOut } from "firebase/auth";

export default function Home() {
  function logout() {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
        console.log("error" + error);
      });
  }
  return (
    <div>
      {/* <Banner title="Delphos" /> */}
      <div className="banner">
        <div className="overlay"></div>
        <div className="head-content">
          <img
            className="delphos-logo-banner"
            src="/logos/word-white.png"
            alt="Delphos Logo"
          />
        </div>
        <button onClick={logout} className="logout-button">
          Log out
        </button>
      </div>
      <div className="caption-container">
        <p className="caption-text">
          The Definitive Source for Unlocking Innovative Financial Solutions
        </p>
        <div className="caption-parallelogram"></div>
      </div>

      <div className="home-container">
        <h3 className="stat-description">Over</h3>
        <Dial char="$" height={85} />
        <Dial char="2" height={85} />
        <Dial char="0" height={85} />
        <h2 className="stat-description stat-inline">Billion</h2>
        <h3 className="stat-description">
          in impact financing successfully deployed
        </h3>

        <hr />

        <h3 className="stat-description">Over</h3>
        <Dial char="$" height={85} />
        <Dial char="1" height={85} />
        <Dial char="0" height={85} />
        <h2 className="stat-description stat-inline">Billion</h2>
        <h3 className="stat-description">
          pipeline of targeted impact transactions
        </h3>

        <hr />
        <Dial char="3" height={83} />
        <Dial char="2" height={83} />
        <h4 className="stat-description stat-inline">years track record</h4>

        <h3 className="stat-description">with over</h3>

        <div className="stat-center">
          <Dial char="5" height={83} />
          <Dial char="0" height={83} />
          <Dial char="0" height={83} />
        </div>

        <h4 className="stat-description stat-inline">
          successfull transactions in over
        </h4>

        <div className="stat-center">
          <Dial char="8" height={83} />
          <Dial char="0" height={83} />

          <h3 className="stat-description stat-inline">countries</h3>
        </div>
        <hr />
      </div>

      <div className="map-container">
        <h1>Discover Delphos</h1>
        <h3>See what Delphos has done all around the world.</h3>
        <HeatMap />
      </div>
    </div>
  );
}
