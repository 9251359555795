// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBg-q-r8Na_ek-Ei93L8c2QXcd6wkHAgKM",
  authDomain: "webnews-270f7.firebaseapp.com",
  databaseURL: "https://webnews-270f7.firebaseio.com",
  projectId: "webnews-270f7",
  storageBucket: "webnews-270f7.appspot.com",
  messagingSenderId: "899873732269",
  appId: "1:899873732269:web:3ba1c871b7a31a6d63b824",
  measurementId: "G-BQBLLRFTN2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth();

export const db = getFirestore(app);

// export const addListener = onAuthStateChanged(auth, (user) => {
//   if (user) {
//     // User is signed in, see docs for a list of available properties
//     // https://firebase.google.com/docs/reference/js/firebase.User
//   } else {
//     // User is signed out
//     // ...

//     if (
//       window.location.pathname !== "/welcome" ||
//       window.location.pathname !== "/login" ||
//       window.location.pathname !== "/signup"
//     ) {
//       window.location.href = "/welcome";
//     }
//   }
// });

export default app;
