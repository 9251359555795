import React from "react";
import "../css/home.css";

export default function Home(props: { char: string; height: number }) {
  return (
    <div
      className="ticker-container"
      style={{ height: `${props.height}px`, width: `${props.height * 0.54}px` }}
    >
      <div
        className="top-dial"
        style={{ height: `${props.height / 2 - 1}px` }}
      ></div>
      <div
        className="bottom-dial"
        style={{ height: `${props.height / 2 - 1}px` }}
      ></div>

      <h1 className="dial-char" style={{ fontSize: `${props.height * 0.6}px` }}>
        {props.char}
      </h1>
    </div>
  );
}
